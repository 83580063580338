<template>
	<div class="page">
		<div class="title">
			<p>{{lang==6?'企业社会责任':'Corporate Social Responsibility'}}</p>
			<p class="yellowLine"></p>
		</div>
		<div class="content">
			<div class="top">
				<div class="text" v-if="lang==6">
					在明确的目标和承诺下，宝爱捷（中国）希望通过企业社会责任（CSR）计划，将经济、社会和环境考量纳入组织的战略和运作中。<br>
					集团的主要目标是提供优质的产品和服务给客户，保持业务的增长，以及改善环境和社区的福祉。<br>
					人力是宝爱捷（中国）的重要资产，因此集团特别关注员工的道德行为，价值观念和生活质量。我们认为企业社会责任是双赢的解决方案，除了有助于环境和社会的发展，还是可持续增长的关键。
				</div>
				<div class="text" v-else>
					With clear objectives and commitments, PAIG (China) hopes to integrate economic, social and
					environmental considerations into the organization's strategy and operation through the corporate
					social responsibility (CSR) plan.<br>
					The main objective of the group is to provide high-quality products and services to customers,
					maintain business growth, and improve the environment and community welfare.<br>
					Human resources are an important asset of PAIG (China), so the group pays special attention to
					the moral behavior, values and quality of life of employees. We believe that corporate social
					responsibility is a win-win solution. In addition to contributing to the development of the
					environment and society, it is also the key to sustainable growth.
				</div>
				<div class="bottom" v-if="lang==6">
					<p>宝爱捷（中国）的企业社会责任包括了以下几个主要范畴：</p>
					<div>
						<div>
							<b>员工福祉</b>
							<br />
							企业的成功和可持续发展，离不开全体员工的努力和付出。除了参与和组织集团层面或者当地经销商的公益活动，我们还不定时组织开展丰富多彩的员工活动，比如篮球、足球等体育活动，健康体检、不同主题的研讨会，以及其他多元化的娱乐活动。
						</div>
						<div>
							<b>社会公益</b>
							<br />
							宝爱捷（中国）致力于成为汽车经销商的行业标杆，在追求商业价值实现业务发展的同时，也一直寻求社会价值的共同提升，在企业社会责任上担当着积极的角色。我们坚守商业伦理道德，全力支持与集团价值一致的公益活动，为偏远欠发达地区的教育事业贡献一份力量。我们鼓励员工积极投身公益慈善事业，心系社会回馈社会。
						</div>
					</div>
				</div>
				<div class="bottom" v-else>
					<p>The corporate social responsibility of PAIG (China) includes the following main categories:
					</p>
					<div>
						<div>
							<b>Employee Well-Being</b>
							<br />
							The success and sustainable development of an enterprise can not be separated from the
							efforts
							and dedication of all employees. In addition to participating in and organizing public
							welfare
							activities at the group level or local dealers, we also organize and carry out colorful
							staff
							activities from time to time, such as basketball, football and other sports activities,
							physical
							examination, seminars on different topics, and other diversified entertainment activities.
						</div>
						<div>
							<b>Social Welfare</b>
							<br />
							PAIG (China) is committed to becoming the industry benchmark of automobile dealers.
							While
							pursuing commercial value and business development, it has also been seeking common
							improvement
							of social value and playing an active role in corporate social responsibility. We adhere to
							business ethics, fully support public welfare activities consistent with the group's values,
							and
							contribute to the education in remote and underdeveloped areas. We encourage our employees
							to
							actively participate in public welfare and charity undertakings, care about the society and
							give
							back to the society.
						</div>
					</div>
				</div>
			</div>
			<div class="mode2" v-show="imgList.length>0">
				<swiper :options="swiperOption" ref="mySwiper1" v-if="imgList.length > 0">
					<swiper-slide class="swiper-slide" v-for="(item,index) in imgList" :key="index">
						<img :src="item.image" mode="widthFit" class="banner" />
					</swiper-slide>
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</div>
		</div>
	</div>
</template>

<script>
	import mixinLang from '../components/mixin.js'
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js"
	import 'swiper/dist/css/swiper.css'
	export default {
		mixins: [mixinLang],
		components: {
			swiper,
			swiperSlide
		},
		data() {
			return {
				swiperOption: {
					slidesPerView: 3,
					spaceBetween: 30,
					centeredSlides: true,
					loop: true,
					loopFillGroupWithBlank: true,
					observer: true,
					observeParents: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
					pagination: {
						el: ".swiper-pagination",
						clickable: true //允许分页点击跳转
					}
				},
				imgList: [],
			}
		},
		mounted() {
			this.$api.getAtlasList({
				cate_no: 'er654566'
			}, {
				page: 1,
				limit: 100
			}).then(res => {
				this.imgList = res.list
				if (this.imgList.length < 3) {
					this.swiperOption.slidesPerView = 1
					this.swiperOption.autoplay = false
				}
				this.$nextTick(() => {
					this.$refs.mySwiper1 ? this.$refs.mySwiper1.swiper.slideTo(1, 1000, false) : ''
				})
			})

		},
		methods: {
			getInfo() {

			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding: 170px 0 70px;
		font-size: 18px;
	}

	.title {
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 40px;
		font-weight: bold;
		color: #FFF;
		text-transform: uppercase;
		padding: 0 111px;
	}

	.b {
		font-weight: bold;
		font-size: 24px;
	}

	.yellowLine {
		margin: 50px 0 40px;
	}

	ul {
		margin-left: 15px;
	}

	.content {
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 20px;
		font-weight: 350;
		line-height: 36px;
		line-height: 46px;
		width: 100%;

		.top {
			margin: 0 60px;
			background-color: #EEEEEE;
			border-radius: 16px;
			padding: 56px 51px;

			// display: flex;
			// justify-content: space-between;
			/deep/ .swiper-container {
				margin: 0;
			}

			/deep/ .swiper-wrapper {
				width: 540px;
			}

			/deep/ .swiper-slide {
				width: 540px !important;
			}

			img {
				width: 540px;
				height: 300px;
			}

		}
	}

	/deep/.swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		background-color: #D8D8D8;
		opacity: 1;
	}

	/deep/ .swiper-pagination-bullet-active {
		background-color: #97233F;
	}

	.bottom {
		position: relative;

		&::after {
			position: absolute;
			top: 90px;
			left: calc(50% - 1px);
			content: '';
			width: 2px;
			height: calc(100% - 110px);
			background: linear-gradient(180deg, rgba(181, 161, 145, 0.00) 0%, #B5A191 48%, rgba(181, 161, 145, 0.00) 100%);
		}

		>div {
			display: flex;
			justify-content: space-between;

			>div {
				width: 780px;
			}
		}

		p {
			font-family: Montserrat-Bold, sans-serif;
			font-size: 32px;
			font-weight: bold;
			color: #97233F;
			margin: 50px 0;
			text-transform: uppercase;
		}

		span {
			color: #B5A191;
			cursor: pointer;
		}
	}

	.mode2 {

		margin: 50px 60px 0;
		background-color: #EEEEEE;
		border-radius: 16px;

		position: relative;
		height: 560px;
		background-size: 100% 100%;
		background-repeat: no-repeat;

		&::after {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.17);
			z-index: 2;
			left: 0;
			top: 0;
		}

		.banner {
			width: 659px;
			height: 374px;
			border-radius: 14px;
		}

		/deep/ .swiper-container {
			position: relative;
			width: 100%;
			height: 500px;
			z-index: 3;
		}

		/deep/.swiper-pagination {
			display: flex;
			justify-content: space-around;

			&::after {
				position: absolute;
				display: block;
				content: '';
				left: 0;
				top: calc(50% - 1px);
				width: 100%;
				height: 2px;
				background-color: #97233F;

			}
		}

		/deep/.swiper-pagination-bullet {
			width: 18px;
			height: 18px;
			background-color: #97233F !important;
			opacity: 1;
		}

		/deep/ .swiper-pagination-bullet-active {
			position: relative;

			&::after {
				position: absolute;
				display: block;
				content: '';
				border-radius: 50%;
				left: -4.5px;
				top: -4.2px;
				width: 24px;
				height: 24px;
				border: 1px solid #97233F;

			}
		}

		/deep/ .swiper-slide {
			text-align: center;
			font-size: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: 300ms;
			transform: scale(0.8);
		}


		/deep/ .swiper-slide {
			text-align: center;
			font-size: 18px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
			transition: 300ms;
			transform: scale(0.8);
		}

		/deep/ .swiper-slide-active,
		/deep/ .swiper-slide-duplicate-active {
			transform: scale(1);
		}
	}
</style>